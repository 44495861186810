// import styled from "styled-components"

const GoogleIcon = () => (
    <svg width="31" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.98 15.6541c0-.4786-.043-.9388-.1227-1.3807H15.5v2.6111h3.6327c-.1564.8437-.632 1.5586-1.3469 2.0372v1.6937h2.1815c1.2763-1.1751 2.0127-2.9056 2.0127-4.9613Z"
            fill="#4285F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.4998 22.2507c1.8225 0 3.3505-.6044 4.4673-1.6353l-2.1815-1.6937c-.6044.405-1.3776.6443-2.2858.6443-1.758 0-3.2461-1.1873-3.7769-2.7828H9.46777v1.7489c1.11073 2.206 3.39343 3.7186 6.03203 3.7186Z"
            fill="#34A853"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7231 16.7826c-.135-.405-.2117-.8376-.2117-1.2825 0-.4449.0767-.8775.2117-1.2825v-1.7488H9.46795C9.0108 13.38 8.75 14.4109 8.75 15.5001s.2608 2.1201.71795 3.0314l2.25515-1.7489Z"
            fill="#FBBC05"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.4998 11.4347c.991 0 1.8808.3405 2.5804 1.0094l1.936-1.936C18.8472 9.41886 17.3193 8.75 15.4998 8.75c-2.6386 0-4.9213 1.5126-6.03203 3.7186l2.25513 1.7489c.5308-1.5955 2.0189-2.7828 3.7769-2.7828Z"
            fill="#EA4335"
        />
    </svg>
)

const FacebookIcon = () => (
    <svg width="30" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 15.553c0-4.9998-4.0294-9.053-9-9.053s-9 4.0532-9 9.053c0 4.5187 3.29117 8.264 7.5938 8.9431v-6.3262h-2.2852V15.553h2.2852v-1.9945c0-2.2689 1.3436-3.5222 3.3994-3.5222.9847 0 2.0146.1769 2.0146.1769v2.2279h-1.1349c-1.118 0-1.4667.6978-1.4667 1.4137v1.6982h2.4961l-.399 2.6169h-2.0971v6.3262C20.7088 23.817 24 20.0717 24 15.553Z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M6 6.5h18v18H6z" />
            </clipPath>
        </defs>
    </svg>
)

// const StyledLinkGoogle = styled.a`
//     font-family: 'Lato';
//     color: #1C3054;
//     font-size: 18px;
//     line-height: 18px;
//     min-height: 50px;
//     width: calc((100% - 10px) / 2);
//     text-decoration: none;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #F8FAFC;
//     border-radius: 10px;
//     border: 1px solid #E7ECF1;
//     padding: 4px;
//     @media (max-width: 767.98px) {
//         width: 100%;
//     }
// `

// const StyledLinkFacebook = styled.a`
//     font-family: 'Lato';
//     color: white;
//     font-size: 18px;
//     line-height: 18px;
//     min-height: 50px;
//     width: calc((100% - 10px) / 2);
//     text-decoration: none;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #1877F2;
//     border-radius: 10px;
//     border: 1px solid #E7ECF1;
//     padding: 4px;
//     @media (max-width: 767.98px) {
//         width: 100%;
//     }
// `

// const StyledDiv = styled.div`
//     display: flex;
//     justify-content: space-between;
//     gap: 10px;
//     @media (max-width: 767.98px) {
//         flex-direction: column;
//     }
// `

export default function SocialLinks() {
    const handleClickSocialLogin = (event) => {
        event.preventDefault()
        const { href, dataset } = event.currentTarget
        const { provider } = dataset

        window?.posthog?.capture("Clicked Social Login", {
            provider,
        })

        const rootUrl = window.location.host.includes("divorce.com")
            ? "https://divorce.com"
            : "https://staging.completecase.com:8190"

        // console.log(new URL(href).pathname)

        window.location.href = `${rootUrl}${new URL(href).pathname}`
    }

    const divStyles = {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px", // Note that gap is not fully supported in all browsers, especially IE
    }

    const StyledLinkGoogle = {
        fontFamily: "Lato, sans-serif",
        color: "#1C3054",
        fontSize: "18px",
        lineHeight: "18px",
        minHeight: "50px",
        width: "calc((100% - 10px) / 2)", // Note that calc() might not work in all situations
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F8FAFC",
        borderRadius: "10px",
        border: "1px solid #E7ECF1",
        padding: "4px",
    }

    const StyledLinkFacebook = {
        fontFamily: "sans-serif",
        color: "white",
        fontSize: "18px",
        lineHeight: "18px",
        minHeight: "50px",
        width: "calc((100% - 10px) / 2)", // Note that calc() might not work in all situations
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#1877F2",
        borderRadius: "10px",
        border: "1px solid #1877F2",
        padding: "4px",
    }

    return (
        <div style={divStyles}>
            <a
                style={StyledLinkGoogle}
                href="https://divorce.com/social-auth/google/login/"
                data-provider="google"
                onClick={handleClickSocialLogin}
            >
                <GoogleIcon />
                Google
            </a>
            <a
                style={StyledLinkFacebook}
                href="https://divorce.com/social-auth/facebook/login/"
                data-provider="facebook"
                onClick={handleClickSocialLogin}
            >
                <FacebookIcon />
                Facebook
            </a>
        </div>
    )
}
